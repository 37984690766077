import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';

// TODO: Fix this to be able to migrate this file to typescript
// Importing the Tide subnav so the styles are added to the DOM for our forked component
//eslint-disable-next-line
import { Subnav } from '@driftt/tide-subnav';
import { access } from '@driftt/traffic-control';

import FoldersSubnav from 'components/videoLibrarySubnav/FoldersSubnav';
import PatchedTideSubnav from 'components/videoLibrarySubnav/PatchedTideSubnav';
import { UserContext } from 'context/UserContext';

function NavigationSidebar() {
  const { userHasFeature } = useContext(UserContext);

  const settingsNodes = [
    {
      label: 'Account',
      path: '/video/settings/account',
      target: '_self',
    },
    {
      label: 'Video notifications',
      path: '/video/settings/notifications',
      target: '_self',
    },
    {
      label: 'Privacy',
      path: '/video/settings/privacy',
      target: '_self',
    },
    {
      label: 'Conversations',
      path: '/video/settings/welcome-message',
      target: '_self',
    },
    {
      label: 'Custom branding',
      path: '/video/settings/branding',
      target: '_self',
      hide: !(
        access.isAdmin() ||
        access.hasFeatures({
          featureName: 'company_details',
          accessLevel: 'WRITE',
        })
      ),
    },
  ];

  const nodesList = [
    {
      label: 'SETTINGS',
      expanded: window.location.pathname.indexOf('/settings') > -1,
      nodes: settingsNodes,
    },
  ];

  if (userHasFeature('HAS_TEAM_VIDEO_REPORTS') || userHasFeature('TEAMMATE_VIDEO_DETAILS_REPORT')) {
    nodesList.unshift({
      label: 'REPORTS',
      expanded: window?.location?.pathname?.indexOf('/reports') > -1,
      nodes: [
        {
          label: 'Team videos',
          path: '/video/reports/',
          target: '_self',
        },
        {
          label: 'Team performance',
          path: '/video/reports/performance/',
          target: '_self',
        },
      ],
    });
  }

  return (
    <PatchedTideSubnav
      className="sidebar"
      title="Video"
      open={true}
      NavLink={NavLink}
      customElement={<FoldersSubnav />}
      nodes={nodesList}
    />
  );
}

export default NavigationSidebar;
