import React, { useContext } from 'react';

import PropTypes from 'prop-types';

import { NavigationHeader } from '@driftt/tide-core';

import FolderContext from 'context/FolderContext';
import useToastNotifications from 'hooks/useToastNotifications';
import { SOURCE_TYPE_GIF, SOURCE_TYPE_MEETING_RECORDING, SOURCE_TYPE_VIDEO } from 'utils/constants';

import FolderBreadcrumbs from './FolderBreadcrumbs';
import FolderTitle from './FolderTitle';

import './styles.css';

function LibraryNavigationHeader({ sourceType, team }) {
  const { folder } = useContext(FolderContext);
  const { ToastContainer } = useToastNotifications();

  let title = team ? 'Team ' : 'My ';

  switch (sourceType) {
    case SOURCE_TYPE_VIDEO:
      title += 'videos';
      break;
    case SOURCE_TYPE_GIF:
      title += 'GIFs';
      break;
    case SOURCE_TYPE_MEETING_RECORDING:
      title += 'Zoom recordings';
      break;
    default:
      title += 'recordings';
      break;
  }

  if (folder) {
    title = <FolderTitle key={folder.id} folderId={folder.id} folderTitle={folder.title} />;
  }

  return (
    <>
      <div className="folder-breadcrumbs-container">
        <FolderBreadcrumbs />
      </div>
      <NavigationHeader title={title} />
      <ToastContainer className="video-library__toast-container" />
    </>
  );
}

LibraryNavigationHeader.propTypes = {
  sourceType: PropTypes.oneOf([SOURCE_TYPE_VIDEO, SOURCE_TYPE_GIF, SOURCE_TYPE_MEETING_RECORDING]),
  team: PropTypes.bool.isRequired,
  refreshTable: PropTypes.func,
};

export default LibraryNavigationHeader;
